import { SerializedFarmConfig } from 'config/constants/types'
import BigNumber from 'bignumber.js'
import { BIG_TEN, BIG_ZERO } from '../../utils/bigNumber'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { fetchMasterChefData, fetchMasterChefPCoinRewardPerBlock, fetchMasterChefTotalAllocPoint } from './fetchMasterChefData'
import { SerializedFarm } from '../types'
import { simpleRpcProvider } from 'utils/providers'
const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]): Promise<SerializedFarm[]> => {
  const farmResult = await fetchPublicFarmsData(farmsToFetch)
  const masterChefResult = await fetchMasterChefData(farmsToFetch)
  const currentBlock = await simpleRpcProvider.getBlockNumber()
  const pCoinRewardPerBlock = await fetchMasterChefPCoinRewardPerBlock()
  const totalAllocPoint = await fetchMasterChefTotalAllocPoint()
  //console.log("FetchFarms Global Object", { currentBlock, totalAllocPoint, pCoinRewardPerBlock })

  return farmsToFetch.map((farm, index) => {
    try {
      const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
        farmResult[index]
      const [info, activityInfo] = masterChefResult[index]
      // console.group(`[Remote Data Transport]public farm data: ${farm.lpSymbol}(pid=${farm.pid})`)
      //console.log('poolInfo=>', info)
      //console.log('activityInfo=>', activityInfo)
      //console.log('totalAllocPoint=>', totalAllocPoint)
      //console.log('pCoinRewardPerBlock=>', pCoinRewardPerBlock)
      //console.log('farmResult=>', farmResult[index])

      // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Raw amount of token in the LP, including those not staked
      const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
      const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

      // Amount of quoteToken in the LP that are staked in the MC
      const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)


      // Total staked in LP, in quote token value
      const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))
      const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
      const alreadyReward = info ? new BigNumber(info.alreadyReward?._hex) : BIG_ZERO
      const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint._hex)) : BIG_ZERO


      const isShare = info ? info?.isShare : false
      const isRewardPCoin = info ? info?.isRewardPCoin : false

      // currentBlock > startBlock + totalRewardBlock // 就结束
      let isFinished = info ? info?.isFinished : false
      let _rewardPerBlock = '0x0'
      if (activityInfo) {
        const { rewardPerBlock, startBlock, totalRewardBlock } = activityInfo
        _rewardPerBlock = rewardPerBlock._hex
        // isFinished = new BigNumber(currentBlock).isGreaterThan(new BigNumber(startBlock?._hex).plus(new BigNumber(totalRewardBlock?._hex)))
      }

      let isShow = !(allocPoint.eq(0) && alreadyReward.eq(0) && (info?.isFinished ?? true))

      //console.log('multiplier=>', `${allocPoint.toString()}X`)
      if (farm.pid == 10) {
        //console.log('quoteTokenAmountTotal=>', quoteTokenAmountTotal.toJSON())
        //console.log('tokenAmountTotal=>', tokenAmountTotal.toJSON())
      }

      const res = {
        ...farm,
        token: farm.token,
        quoteToken: farm.quoteToken,
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.toString()}X`,
        isFinished: isFinished,
        isShare: isShare,
        isShow: isShow,
        isRewardPCoin: isRewardPCoin,

        allocPoint: allocPoint.toJSON(),
        totalAllocPoint: totalAllocPoint._hex,
        pCoinRewardPerBlock: pCoinRewardPerBlock._hex,
        rewardPerBlock: _rewardPerBlock,
        rewardToken: info?.rewardToken ?? ""
      }
      //console.log('farmRES', res)
      console.groupEnd()
      return res
    } catch (ex) {
      console.error(ex)
    }
  })
}

export default fetchFarms
