export default {
  masterChef: {
    97: "0x35C972547BfB5cfBfFf78B9DC8280Ef695559b4c",
    56: "0xD99405a7880Ef4e8eE9b1270917b1E622C41566D",
  },
  sousChef: {
    97: "0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a",
    56: "0x35C972547BfB5cfBfFf78B9DC8280Ef695559b4c",
  },
  lotteryV2: {
    97: "0x5790c3534F30437641541a0FA04C992799602998",
    56: "0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c",
  },
  multiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
  },
  pancakeProfile: {
    56: "0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a",
    97: "0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A",
  },
  pancakeRabbits: {
    56: "0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07",
    97: "0x60935F36e4631F73f0f407e68642144e07aC7f5E",
  },
  bunnyFactory: {
    56: "0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf",
    97: "0x707CBF373175fdB601D34eeBF2Cf665d08f01148",
  },
  claimRefund: {
    56: "0xE7e53A7e9E3Cf6b840f167eF69519175c497e149",
    97: "",
  },
  pointCenterIfo: {
    56: "0x3C6919b132462C1FEc572c6300E83191f4F0012a",
    97: "0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3",
  },
  bunnySpecial: {
    56: "0xFee8A195570a18461146F401d6033f5ab3380849",
    97: "0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C",
  },
  tradingCompetition: {
    56: "0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA",
    97: "0xC787F45B833721ED3aC46E99b703B3E1E01abb97",
  },
  tradingCompetitionV2: {
    56: "0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4",
    97: "",
  },
  easterNft: {
    56: "0x23c41D28A239dDCAABd1bb1deF8d057189510066",
    97: "0x24ec6962dbe874F6B67B5C50857565667fA0854F",
  },
  cakeVault: {
    56: "0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC",
    97: "",
  },
  ifoPool: {
    56: "0x1B2A2f6ed4A1401E8C73B4c2B6172455ce2f78E8",
    97: "",
  },
  predictions: {
    56: "0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA",
    97: "",
  },
  chainlinkOracle: {
    56: "0xD276fCF34D54A926773c399eBAa772C12ec394aC",
    97: "",
  },
  bunnySpecialCakeVault: {
    56: "0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4",
    97: "",
  },
  bunnySpecialPrediction: {
    56: "0x342c99e9aC24157657095eC69CB04b73257e7A9C",
    97: "",
  },
  bunnySpecialLottery: {
    56: "0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2",
    97: "0x382cB497110F398F0f152cae82821476AE51c9cF",
  },
  bunnySpecialXmas: {
    56: "0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783",
    97: "",
  },
  farmAuction: {
    56: "0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2",
    97: "0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe",
  },
  AnniversaryAchievement: {
    56: "0x787980da5491118C3cB33B21aB50c8c379D2C552",
    97: "0x981aE96378e770DE44F89cD9175E708f9EDB70a9",
  },
  nftMarket: {
    56: "0x17539cCa21C7933Df5c980172d22659B8C345C5A",
    97: "0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2",
  },
  nftSale: {
    56: "0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E",
    97: "0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46",
  },
  pancakeSquad: {
    56: "0x0a8901b0E25DEb55A87524f0cC164E9644020EBA",
    97: "0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86",
  },
  farmMasterChef: {
    56: "0xBB2F771331eB699D069F9532f6507D861b1da5ae",
    97: "0x11c8619A2c984B99B077858811FA8aeDB40006F0",
  },
  farmMasterChef2: {
    56: "0x0cF4071940782b640d0b595Cb17bDf3E90869d70",
    97: "0x11c8619A2c984B99B077858811FA8aeDB40006F0",
  },
  calcPrice: {
    97: "",
    56: "0xB6bdED3e1bF6c55CD6db75d1169944944A766d73",
  },
  pg: {
    56: "0xfA82075A6d8F85Be9146e64e0F02BaA849F8E8Fb",
    97: "0xd5aC50D4Cd106d36E16dd674d906B82a00e6f913",
  },
  oskDao: {
    56: "0xC5db5aFee4C55DfAD5F2b8226C6ac882E6956a0A",
    97: "0xe1014343FE487199440626E9236D3AAbdE5d21D4",
  },
  pnn: {
    56: "0x59a25CB6A4fE264d698ba9a2816C47778280aBbA",
    97: "0x9041E210cEC546Ede4DBC502335f47E56A081d9a",
  },
  pnnDistributor: {
    56: "0x1E6AEA677B5DD37Aad6059cA958b8d02891DD875",
    97: "0xC28a45123980C63DdaCfdb3f0Bd97861c00fB106",
  },
  pnnMasterChef: {
    56: "0xFC20065De08689867662b6dc683024d12e697495",
    97: "0x179D976D939D029D93F312BE4664C0a0Dd69eB3C",
  },
  createToken: {
    97: '0xaa740B44709E5D26DF0a24a96F9b997cE0B9cD84',
    56: '',
  },
  fakeW3Token: {
    97: '0x0D0302925ed17743778B0F06BaaB84cB459eF002',
    56: '0xE8861614d9d52cDE9375565a4F36eadD7848dbE2',
  },
  createFarm: {
    97: '0x39dFAe79ca786E9758f709cCCCa9C5E00F025308',
    56: '0x8FFC32A6C1D7B83Cd7D075123056877063f34266',
  },
  createDivToken: {
    97: '0x473fa5Ee46e1A1A306500BdB7aF8c1F41D7FD3eE',
    56: '',
  },
  lock:{
    97: '0xb9bF06bAD8c054c9247CC8e2CA012EF5aC27B497',//0xb9bF06bAD8c054c9247CC8e2CA012EF5aC27B497,w3
    56: '0xb9bF06bAD8c054c9247CC8e2CA012EF5aC27B497',//0x407993575c91ce7643a4d4cCACc9A98c36eE1BBE,pinksale
  },
  launchpad:{
    97: '0xdd5Bd9a6C2c4191b925D62992145296f1517Ade9',//0xb9bF06bAD8c054c9247CC8e2CA012EF5aC27B497,w3
    56: '0xdd5Bd9a6C2c4191b925D62992145296f1517Ade9',//0x407993575c91ce7643a4d4cCACc9A98c36eE1BBE,pinksale
  }
};
