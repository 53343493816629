import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './newType'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid:0,
    lpSymbol: 'OSK-DAO/USDT',
    isHelperPrice: false,
    multiplier:'2284X',
    lpAddresses: {
      97: '',
      56: '0x11e82E2997a05c478C3348376FDb493671e2F935',
    },
    token: serializedTokens.oskdao,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '948X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '697X'
      },  
      {
        ...serializedTokens.fog,
        multiplier: '161X'
      }         
    ],
  },     

  {
    pid:2,
    lpSymbol: 'W3/USDT',
    isHelperPrice: false,
    multiplier:'410X',
    lpAddresses: {
      97: '',
      56: '0x89eb935daF6beBCEa485705c719c47fDDF70a41F',
    },
    token: serializedTokens.w3,
    quoteToken: serializedTokens.bscusd,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '40X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '12X'
      }   
    ],
  },
  {
    pid:18,
    lpSymbol: 'FOG/USDT',
    isHelperPrice: false,
    multiplier:'1399X',
    lpAddresses: {
      97: '',
      56: '0x46f2F0B439EBA0F7fb59444e0701bC7B5632c141',
    },
    token: serializedTokens.fog,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '1X'
      },
      {
        ...serializedTokens.fog,
        multiplier: '839X'
      }
    ],
  },
  {
    pid:13,
    lpSymbol: 'HO/OSK-DAO',
    isHelperPrice: false,
    multiplier:'707X',
    lpAddresses: {
      97: '',
      56: '0xD06d9b1cBFa4C0D0503CD74383D515ACac1F6383',
    },
    token: serializedTokens.ho,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.hd,
        multiplier: '0X'
      },
      {
        ...serializedTokens.w3,
        multiplier: '9X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },
  
  {
    pid:16,
    lpSymbol: 'HO/HOS',
    isHelperPrice: false,
    multiplier:'206X',
    lpAddresses: {
      97: '',
      56: '0x2D2A392EE5740ffc4AaFF5B61115DCF77acFc49F',
    },
    token: serializedTokens.ho,
    quoteToken: serializedTokens.hos,
    rewardTokenList: [
      {
        ...serializedTokens.hd,
        multiplier: '0X'
      },
      {
        ...serializedTokens.w3,
        multiplier: '2X'
      }
    ],
  },


  // 隐藏池
      //  {
      //   pid:1,
      //   lpSymbol: 'W3Farm/USDT',
      //   isHelperPrice: false,
      //   multiplier:'286X',
      //   lpAddresses: {
      //     97: '',
      //     56: '0x2ad34C396133097e3F26a733768d68836A0C926f',
      //   },
      //   token: serializedTokens.w3farm,
      //   quoteToken: serializedTokens.bscusd,
      //   rewardTokenList: [
      //     {
      //       ...serializedTokens.pg,
      //       multiplier: '286X'
      //     },
      //     {
      //       ...serializedTokens.w3,
      //       multiplier: '0X'
      //     }           
      //   ],
      // },



      // 已完成
  {
    pid:14,
    lpSymbol: 'HO/HBG',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x2ce9De6C79832891052D6D4Aa5c23E5dAAF212B1',
    },
    token: serializedTokens.ho,
    quoteToken: serializedTokens.hbg,
    rewardTokenList: [
      {
        ...serializedTokens.hd,
        multiplier: '0X'
      }
    ],
  },
//   {
//     pid:3,
//     lpSymbol: 'GNA/OSK-DAO',
//     isHelperPrice: false,
//     isEnd:true,
//     isFinished:true,
//     multiplier:'0X',
//     lpAddresses: {
//       97: '',
//       56: '0x3123E9bbDA14E7f39834e8E3ee9A8193f0CbE806',
//     },
//     token: serializedTokens.gna,
//     quoteToken: serializedTokens.oskdao,
//     rewardTokenList: [
//       {
//         ...serializedTokens.w3,
//         multiplier: '0X'
//       },
//       {
//         ...serializedTokens.pg,
//         multiplier: '-1X'
//       },
//       {
//         ...serializedTokens.wss,
//         multiplier: '0X'
//       }
//     ],
//   },
  {
    pid:7,
    lpSymbol: 'DSN/OSK-DAO',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x8F07469d639C088E85121E885578B4A54b07Cee6',
    },
    token: serializedTokens.dsn,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:6,
    lpSymbol: 'MMF/USDT',
    isHelperPrice: false,
    multiplier:'0X',
    isEnd:true,
    isFinished:true,
    lpAddresses: {
      97: '',
      56: '0xE6695dec131C8616c856f1B60A1145920Ec2357a',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.bscusd,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '-1X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '-1X'
      },
      {
        ...serializedTokens.mmf,
        multiplier: '0X'
      },
    ],
  },
  {
    pid:10,
    lpSymbol: 'RRB/USDT',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0xD577A5C16B08695230512D178855d0A9982c8864',
    },
    token: serializedTokens.rrb,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:17,
    lpSymbol: 'HO/IGT',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x6219395E6fF78344890a110A6AE8095E84dE5f4F',
    },
    token: serializedTokens.ho,
    quoteToken: serializedTokens.igt,
    rewardTokenList: [
      {
        ...serializedTokens.hd,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:4,
    lpSymbol: 'KFC/OSK-DAO',
    isHelperPrice: false,
    multiplier:'0X',
    isEnd:true,
    isFinished:true,
    lpAddresses: {
      97: '',
      56: '0x1C758366839A138651c1b522746a47e23Ad56AE8',
    },
    token: serializedTokens.kfc,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:9,
    lpSymbol: 'TOR/USDT',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x4aD143c51617701664E573a211034dE2298832c6',
    },
    token: serializedTokens.tor,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:11,
    lpSymbol: 'QJ/OSK-DAO',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0xa399aa39B046e88Cee5bDbCB6d9cae6c599777D8',
    },
    token: serializedTokens.qj,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      }
    ],
  },

  {
    pid:5,
    lpSymbol: 'XT/OSK-DAO',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0xBf45fC745Fe0a797FD7f2D03510a05E38FBe5685',
    },
    token: serializedTokens.xt,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:8,
    lpSymbol: 'OSK-TOP/OSK-DAO',
    isHelperPrice: false,
    multiplier:'0X',
    isEnd:true,
    isFinished:true,
    lpAddresses: {
      97: '',
      56: '0x03A7d6550985286fD10119735B087a70607fcBe7',
    },
    token: serializedTokens.osktop,
    quoteToken: serializedTokens.oskdao,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:12,
    lpSymbol: 'SPT/USDT',
    isHelperPrice: false,
    isEnd:true,
    isFinished:true,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x9eEf17C9939456EA0d2244479FEEBE437BE6bbD3',
    },
    token: serializedTokens.spt,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },

]

export default farms
