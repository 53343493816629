import React from 'react'
import styled from 'styled-components'
import { ArrowDropDownIcon } from '../Svg'
import Dropdown from '../Dropdown/Dropdown'
import MenuButton from './MenuButton'
import { useMatchBreakpoints } from '../../hooks'

const Box = styled.div`
    position: relative;
    height: 32px;
    background: rgba(242, 242, 242, 0.06);
    border-radius: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    width: 32px;
    @media (min-width: 768px) {
        padding: 0 8px;
        width: auto;
    }
`
const Logo = styled.img`
    width: 24px;
    height: 24px;
`
const ChainName = styled.span`
    white-space: nowrap;
    color: rgba(255, 255, 255, .5);
    border-left: 1px solid rgba(255, 255, 255, .1);
    padding-left: 9px;
    margin-left: 9px;
`

function DropdownTarget() {
    return (
        <>
            <Box>
                <Logo src='../../../images/tokens/pg-logo.png'></Logo>
                <ChainName>BSC</ChainName>
                <ArrowDropDownIcon color='rgba(255, 255, 255, 0.3)' />
            </Box>
        </>
    )
}
const switchNetwork = () => {
    window.open('https://www.w3swap.link', '_blank')
}

const ChainSelector: React.FC = () => {
    const { isMobile, isMd } = useMatchBreakpoints()
    
    return (
        <Dropdown target={<>
            <Box>
                <Logo src='../../../images/tokens/56.png'></Logo>
                {!isMobile && <ChainName>BSC</ChainName>}
                {!isMobile && <ArrowDropDownIcon color='rgba(255, 255, 255, 0.3)' width="24px" />}
            </Box>
            </>}>
                <MenuButton fullWidth style={{ minHeight: '32px', height: 'auto' }} onClick={switchNetwork}>PEGO</MenuButton>
            </Dropdown>
    )
}

export default React.memo(ChainSelector)