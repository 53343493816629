import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import farmsConfig from 'config/constants/newFarmsV2'
import isArchivedPid from 'utils/farmHelpers'
import type { AppState } from 'state'
import priceHelperLpsConfig from 'config/constants/newPriceHelperLps'
import fetchFarms from './fetchFarms'
import getFarmsPrices from './getFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchFarmUserClaimableRewardPCoin,
} from './fetchFarmUser'
import { SerializedFarmsState, SerializedFarm } from '../newType'
import { fetchMasterChefFarmPoolLength } from './fetchMasterChefData'
import { resetUserState } from '../global/actions'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { useGetApiPrices } from 'state/hooks'

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: BIG_ZERO.toString(),
    tokenBalance: BIG_ZERO.toString(),
    stakedBalance: BIG_ZERO.toString(),
    earnings: [],
    w3: []
  },
}))

const initialState: SerializedFarmsState = {
  // @ts-ignore
  data: noAccountFarmConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<
  [SerializedFarm[], number],
  number[],
  {
    state: AppState
  }
>(
  'farms/fetchNewFarmsPublicDataAsync',
  async (pids) => {
    // console.info(pids, 'mepids')
    const poolLength = await fetchMasterChefFarmPoolLength()
    const farmsToFetch = farmsConfig.filter((farmConfig) => farmConfig.pid || farmConfig.pid === 0)
    // const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
    const farmsCanFetch = farmsToFetch
    // Add price helper farms
    const farmsWithPriceHelpers = farmsCanFetch.concat(priceHelperLpsConfig)
    //console.log('获取farms价格1', farmsWithPriceHelpers)
    const farms = await fetchFarms(farmsToFetch)
    // console.info('获取farms价格2', farms)
    // const farmsWithPrices = getFarmsPrices(farms)
    // console.info('获取farms价格3',farms)
    // Filter out price helper LP config farms
    // const farmsWithoutHelperLps = farmsWithPrices.filter((farm: SerializedFarm) => {
    //   return farm.pid || farm.pid === 0
    // })
    return [farms, poolLength]
  },
  {
    condition: (arg, { getState }) => {
      const { farms } = getState()
      if (farms.loadingKeys[stringify({ type: fetchFarmsPublicDataAsync.typePrefix, arg })]) {
        console.debug('farms action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

interface FarmUserDataResponse {
  pid: number
  allowance: BigNumber
  tokenBalance: BigNumber
  stakedBalance: BigNumber
  earnings: BigNumber[]
  w3: BigNumber[]
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  { account: string; pids: number[] },
  {
    state: AppState
  }
>(
  'farms/fetchNewFarmUserDataAsync',
  // @ts-ignore
  async ({ account, pids }) => {
    try {
      // account='0xab75dca11cad8a042b53f4e61c2dc09cacd92022'
      // account='0x3f8fddaE08B2cF2D1E0Ebc17f9bc2b969766CC9F'
      // account='0xb1475b8D52d7F467466A6D41aB6D4cc61d50Cd0C'
      const poolLength = await fetchMasterChefFarmPoolLength()
      const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
      const farmsCanFetch = farmsToFetch
      const userFarmAllowances = await fetchFarmUserAllowances(account, farmsCanFetch)
      const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsCanFetch)
      const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsCanFetch)
      const userFarmEarnings = await fetchFarmUserEarnings(account, farmsCanFetch)
      // console.info(userFarmEarnings, 'userFarmEarnings', farmsToFetch)
      const userClaimableRewardPCoin = await fetchFarmUserClaimableRewardPCoin(account, farmsCanFetch)
      // const userClaimableRewardPCoin = farmsCanFetch.map(m => "0")
      var res = userFarmAllowances.map((farmAllowance, index) => {
        return {
          pid: farmsCanFetch[index].pid,
          allowance: farmAllowance.toString(),
          tokenBalance: userFarmTokenBalances[index].toString(),
          stakedBalance: userStakedBalances[index].toString(),
          earnings: userFarmEarnings[index][0].map((item) => item.toString()),
          w3: userClaimableRewardPCoin[index][0].map((item) => item.toString()),
        }
      })
      // console.info(res, 'userFarmEarnings222')
      return res
    } catch (error) {
      console.error(error)
    }
  },
  {
    condition: (arg, { getState }) => {
      // @ts-ignore
      const { farms } = getState()
      if (farms.loadingKeys[stringify({ type: fetchFarmUserDataAsync.typePrefix, arg })]) {
        console.debug('farms user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const newfarmsSlice2 = createSlice({
  name: 'NewFarms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // @ts-ignore
      state.data = state.data.map((farm) => {
        return {
          ...farm,
          userData: {
            allowance: BIG_ZERO.toString(),
            tokenBalance: BIG_ZERO.toString(),
            stakedBalance: BIG_ZERO.toString(),
            earnings: [],
            w3: [],
          },
        }
      })
      state.userDataLoaded = false
    })
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      const [farmPayload, poolLength] = action.payload
      state.data.forEach((farm, index) => {
        const liveFarmData = farmPayload.find((farmData) => farmData.pid === farm.pid)
        state.data[index] = { ...farm, ...liveFarmData }
      })
      //console.log('获取价格fetchNewFarmsPublicDataAsync', state.data)
      state.poolLength = poolLength
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload &&
        action.payload.length > 0 &&
        action.payload.forEach((userDataEl) => {
          const { pid } = userDataEl
          const index = state.data.findIndex((farm) => farm.pid === pid)
          state.data[index] = { ...state.data[index], userData: userDataEl }
        })
      //console.log('获取价格fetchNewFarmUserDataAsync', state.data)
      state.userDataLoaded = true
    })

    builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.pending, fetchFarmsPublicDataAsync.pending), (state, action) => {
      state.loadingKeys[serializeLoadingKey(action, 'pending')] = true
    })
    builder.addMatcher(
      isAnyOf(fetchFarmUserDataAsync.fulfilled, fetchFarmsPublicDataAsync.fulfilled),
      (state, action) => {
        state.loadingKeys[serializeLoadingKey(action, 'fulfilled')] = false
      },
    )
    builder.addMatcher(
      isAnyOf(fetchFarmsPublicDataAsync.rejected, fetchFarmUserDataAsync.rejected),
      (state, action) => {
        state.loadingKeys[serializeLoadingKey(action, 'rejected')] = false
      },
    )
  },
})

export default newfarmsSlice2.reducer
