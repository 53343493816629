import React, {useCallback, useState} from 'react'
import styled, { useTheme } from 'styled-components'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import Grid from '../../components/Box/Grid'
import Box from '../../components/Box/Box'
import getThemeValue from '../../util/getThemeValue'
import Text from '../../components/Text/Text'
import Heading from '../../components/Heading/Heading'
import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard, { MoreWalletCard } from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const preferredWalletName = localStorage.getItem(walletLocalStorageKey)
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => a.priority - b.priority)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 3, t }) => {
  const [showMore, setShowMore] = useState(false)
  const [showErrorBk, setShowErrorBk] = useState(false)
  const [showErrorW3, setShowErrorW3] = useState(false)

  const theme = useTheme()
  const sortedConfig = getPreferredConfig(config)
  const displayListConfig = showMore ? sortedConfig : sortedConfig.slice(0, displayCount)

  const haveWallet = useCallback((type)=>{
    if(type == 'bitkeep'){
      setShowErrorBk(true);
    }else if(type == 'injected'){
      setShowErrorW3(true);
    }
  },[setShowErrorBk,setShowErrorW3]);

  return (
      <ModalContainer minWidth="320px">
        <ModalHeader background={getThemeValue('colors.gradients.bubblegum')(theme)}>
          <ModalTitle>
            <Heading>{t('Connect Wallet')}</Heading>
          </ModalTitle>
          <ModalCloseButton onDismiss={onDismiss} />
        </ModalHeader>
        <ModalBody>
          <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">
            <Grid gridTemplateColumns="1fr 1fr">
              {displayListConfig.map((wallet) => (
                  <Box key={wallet.title}>
                    <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} onHaventWallet={haveWallet}/>
                  </Box>
              ))}

            </Grid>
          </WalletWrapper>
          <Box p="24px">
            {/* {!showMore && <MoreWalletCard t={t} onClick={() => setShowMore(true)} />}*/}
            {showErrorBk && <div>
              <Text textAlign="center" color="textSubtle" as="p" mb="16px">
                {t('Haven’t got a crypto wallet yet?')}
              </Text>
              <Button
                  as="a"
                  href="https://bitkeep.com/en/download?type=2"
                  width="100%"
                  {...getExternalLinkProps()}
              >
                {t('Downlaod')+'BitKeep'}
              </Button>
            </div>}

            {showErrorW3 && <div>
              <Text textAlign="center" color="textSubtle" as="p" mb="16px">
                {t('Haven’t got a crypto wallet yet?')}
              </Text>
              <Button
                  as="a"
                  href="https://w3wallet.link/#/"
                  width="100%"
                  {...getExternalLinkProps()}
              >
                {t('Downlaod')+'W3Wallet'}
              </Button>
            </div>}

          </Box>
        </ModalBody>
      </ModalContainer>
  )
}

export default ConnectModal
