import erc20 from 'config/abi/erc20.json'
import chunk from 'lodash/chunk'
import { getAddress, getFarmMasterChefAddress } from 'utils/addressHelpers'
import multicall,{ multicallv2 } from 'utils/multicall'
import { SerializedFarm } from '../newType'
import { SerializedFarmConfig } from '../../config/constants/newType'

const fetchFarmCalls = (farm: SerializedFarm) => {
  const { lpAddresses, token, quoteToken } = farm
  const lpAddress = getAddress(lpAddresses)
  
  return [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [getFarmMasterChefAddress()],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
  ]
}

export const fetchPublicFarmsData = async (farms: SerializedFarmConfig[]): Promise<any[]> => {
  const farmCalls = farms.flatMap((farm) => fetchFarmCalls(farm))
  // console.info(farmCalls,'farmCalls')
  const chunkSize = farmCalls.length / farms.length
  const farmMultiCallResult = await multicall(erc20, farmCalls)
  // console.info(farmMultiCallResult,'farmMultiCallResult')
  return chunk(farmMultiCallResult, chunkSize)
}
