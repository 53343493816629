import masterchefABI from 'config/abi/farmChef.json'
import chunk from 'lodash/chunk'
import multicall,{ multicallv2 } from 'utils/multicall'
import { SerializedFarmConfig } from '../../config/constants/newType'
import { SerializedFarm } from '../newType'
import { getFarmMasterChefAddress } from '../../utils/addressHelpers'
import { getFarmMasterchefContract } from '../../utils/contractHelpers'

const masterChefAddress = getFarmMasterChefAddress()
const masterChefContract = getFarmMasterchefContract()

export const fetchMasterChefFarmPoolLength = async () => {
  const poolLength = 1 // await masterChefContract.poolLength()
  return poolLength
}
export const fetchMasterChefTotalAllocPoint = async () => {
  const totalAllocPoint = 0 // await masterChefContract.totalAllocPoint()
  return totalAllocPoint
}

const rewardBlockFarmCalls = (farm: SerializedFarm) => {
  const { pid, rewardTokenList } = farm
  const calls = rewardTokenList.map((e, i) => {
    return {
      address: masterChefAddress,
      name: 'getTokenRate',
      params: [pid, i],
    }
  })
  return calls
}

export const fetchMasterChefPCoinRewardPerBlock = async (farms: SerializedFarmConfig[]): Promise<any[]> => {
  console.log('-----fetchMasterChefPCoinRewardPerBlock')
  const masterChefCalls = farms.map((farm) => rewardBlockFarmCalls(farm))
  const masterChefAggregatedCalls = masterChefCalls.filter((masterChefCall) => masterChefCall[0] !== null).flat()
  const masterChefMultiCallResultArray = await multicallv2(masterchefABI, masterChefAggregatedCalls).catch(err => {
    console.log('getTokenRate Error:' , err);
  })
  const masterChefMultiCallResult = masterChefMultiCallResultArray.flat();
  let masterChefChunkedResultCounter = 0;
  let res = [];
  farms.forEach(item => {
    const len = item.rewardTokenList.length;
    res.push(masterChefMultiCallResult.slice(masterChefChunkedResultCounter, masterChefChunkedResultCounter + len));
    masterChefChunkedResultCounter += len;
  })
  // console.log('masterChefMultiCallResult', masterChefCalls, res)
  return res;
 
}

const masterChefFarmCalls = (farm: SerializedFarm) => {
  const { pid } = farm
  return pid || pid === 0
    ? {
      address: masterChefAddress,
      name: 'poolInfo',
      params: [pid],
    }
    : null
}

export const fetchMasterChefData = async (farms: SerializedFarmConfig[]): Promise<any[]> => {
  const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm))
  // const chunkSize = masterChefCalls.flat().length / farms.length
  const masterChefAggregatedCalls = masterChefCalls.filter((masterChefCall) => masterChefCall[0] !== null).flat()
  const masterChefMultiCallResult = await multicallv2(masterchefABI, masterChefAggregatedCalls).catch(e => console.log('poolInfo Error:', e))
  // const masterChefChunkedResultRaw = chunk(masterChefMultiCallResult, chunkSize)
  // let masterChefChunkedResultCounter = 0
  // const res = masterChefCalls.map((masterChefCall) => {
  //   if (masterChefCall[0] === null) {
  //     return [null]
  //   }
  //   const data = masterChefChunkedResultRaw[masterChefChunkedResultCounter]
  //   masterChefChunkedResultCounter++
  //   return data
  // })
  // console.info(masterChefMultiCallResult, 'fetchMasterChefDataGet')
  return masterChefMultiCallResult;
}
