import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_29_629)">
                <path d="M12 2L22 8.5V15.5L12 22L2 15.5V8.5L12 2Z" stroke="#E0BD5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 22V15.5" stroke="#E0BD5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 8.5L12 15.5L2 8.5" stroke="#E0BD5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.5L12 8.5L22 15.5" stroke="#E0BD5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 2V8.5" stroke="#E0BD5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_29_629">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icon