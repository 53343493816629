import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './newType'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
/*  {
    pid:0,
    lpSymbol: 'OSK-DAO-OLD/USDT',
    isHelperPrice: false,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x11e82E2997a05c478C3348376FDb493671e2F935',
    },
    token: serializedTokens.oskdao,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },
  {
    pid:1,
    lpSymbol: 'W3-OLD/USDT',
    isHelperPrice: false,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x89eb935daF6beBCEa485705c719c47fDDF70a41F',
    },
    token: serializedTokens.w3,
    quoteToken: serializedTokens.bscusd,
    rewardTokenList: [
      {
        ...serializedTokens.w3,
        multiplier: '0X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '0X'
      }
    ],
  },*/

  //以上为测试

  {
    pid:2,
    lpSymbol: 'PG/USDT',
    isHelperPrice: false,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x4eaD53e15A431c422a99D68C31a3fa203A8Ce95F',
    },
    token: serializedTokens.pg,
    quoteToken: serializedTokens.busdt,
    rewardTokenList: [
      {
        ...serializedTokens.pns,
        multiplier: '600X'
      },
      {
        ...serializedTokens.pg,
        multiplier: '1000X'
      },
      {
        ...serializedTokens.w3new,
        multiplier: '1000X'
      }
    ],
  },
  {
    pid:3,
    lpSymbol: 'PG/OSKDAO',
    isHelperPrice: false,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0xaF4849eD1958e87748E497c160064544969efF25',
    },
    token: serializedTokens.pg,
    quoteToken: serializedTokens.oskdaonew,
    rewardTokenList: [
      {
        ...serializedTokens.pns,
        multiplier: '300X'
      },
      {
        ...serializedTokens.oskdaonew,
        multiplier: '1000X'
      },
      {
        ...serializedTokens.w3new,
        multiplier: '1000X'
      }
    ],
  },
  {
    pid:4,
    lpSymbol: 'PG/W3',
    isHelperPrice: false,
    multiplier:'0X',
    lpAddresses: {
      97: '',
      56: '0x7b7b5F788c31A13eeF477c284E17157E92462ADf',
    },
    token: serializedTokens.pg,
    quoteToken: serializedTokens.w3new,
    rewardTokenList: [
      {
        ...serializedTokens.pns,
        multiplier: '100X'
      },
      {
        ...serializedTokens.w3new,
        multiplier: '1000X'
      }
    ],
  }



]

export default farms
