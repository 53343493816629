import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
    TradeIcon,
    TradeFilledIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
} from 'uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Trade'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      // {
      //   label: t('Trade'),
      //   href: '/swap',
      // },
      // {
      //   label: t('Liquidity'),
      //   href: '/liquidity',
      // }, 
    ],
  },
  // {
  //   label: t('Liquidity'),
  //   icon: EarnIcon,
  //   fillIcon: EarnFillIcon,
  //   href: '/liquidity',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  {
    label: t('Farms1'),
    href: '/newFarmsV2',
    icon: EarnIcon,
    fillIcon: EarnFillIcon,
    showItemsOnMobile: false,
    items: [
      /*{
        label: t('Farms1'),
        href: '/newFarms',
        showItemsOnMobile: true,
      },
      {
        label: t('Farms'),
        href: '/farms',
        showItemsOnMobile: true,
      }*/
    ],
  },
  {
    label: 'IWO',
    href:'/launchpadHome',
    icon: TradeIcon,
    fillIcon: TradeFilledIcon,
    showItemsOnMobile: false,
    items: [
    ],
  },
  {
    label: t('Bridge'),
    href:'https://www.w3bridge.net/',
    icon: TrophyIcon,
    fillIcon: TrophyFillIcon,
    showItemsOnMobile: false,
    items: [
      // {
      //   label: 'IWO',
      //   href: '/iwov1',
      //   showItemsOnMobile: true,
      // }, 
      // {
      // label: t('Application for Main Board'),
      // href: '/iwoapply',
      // showItemsOnMobile: true,
      // },
      // {
      // label: t('Application for GEM Board'),
      // href: '/iwoapplysmall',
      // showItemsOnMobile: true,
      //   }
    ],
  },
  {
    label: t('Tool'),
    href: "#",
    icon: NftIcon,
    fillIcon: NftFillIcon,
    showItemsOnMobile: true,
    items: [
      {
        label:t('Create Tokens'),
        href:'/selfToken',
        showItemsOnMobile: true,
      },
      {
        label:t('Mapping'),
        href:'https://map.w3swap.link/#/',
        showItemsOnMobile: true,
      },
      {
        label:t('W3Lock'),
        href:'/selfLock',
        showItemsOnMobile: true,
      },
      {
        label:t('Create Launchpad'),
        href:'/lanuchpadCreate',
        showItemsOnMobile: true,
      },
    ]
  },
  {
    label: '',
    href: "/more",
    icon: MoreIcon,
    fillIcon: MoreIcon,
    showItemsOnMobile: false,
    items: []
  },


  // {
  //   label: '',
  //   href: '/nodeVote',
  //   icon: MoreIcon,
  //   fillIcon: MoreIcon,
  //   showItemsOnMobile: true,
  //   items: [
  //     {
  //       label:'NFT',
  //       href:'https://www.fstnft.com',
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label: t("Node Qualifying"),
  //       href: "/nodeVote",
  //       showItemsOnMobile: true,
  //     },
  //     {
  //       label: t("Node management"),
  //       href: "/createNode",
  //       showItemsOnMobile: true,
  //     },
  //     {
  //       label:'W3Swap '+t("Twitter"),
  //       href: "https://twitter.com/W3SWAP",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label:'PEGO '+t("Twitter"),
  //       href: "https://twitter.com/pegonetwork",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label:'W3Swap '+t("Telegram Group"),
  //       href:languageCode=='zh-cn'? "https://t.me/w3official":"https://t.me/W3_Global",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label:'PEGO '+t("Telegram Group"),
  //       href:languageCode=='zh-cn'? "https://t.me/pegonetwork2":"https://t.me/pegoofficial",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label:'W3 '+t("Ecology Channel"),
  //       href:languageCode=='zh-cn'? "https://t.me/w3_cn":"https://t.me/W3Global_news",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label: "PEGO "+t("Telegram"),
  //       href: languageCode=='zh-cn'?"https://t.me/PEGO_cn":"https://t.me/PEGO_NEWS",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label: "W3 Medium",
  //       href: "https://medium.com/@w3swap",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //     {
  //       label: "PEGO Medium",
  //       href: "https://medium.com/@pegonetwork",
  //       showItemsOnMobile: true,
  //       type:1
  //     },
  //   ],
  // }
]

export default config
