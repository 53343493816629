import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import MoreHorizontal from '../../components/Svg/Icons/MoreHorizontal'
import { ButtonProps } from '../../components/Button'
import { connectorLocalStorageKey, walletLocalStorageKey } from './config'
import { Login, Config } from './types'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
    onHaventWallet: (types) => void

}

const WalletButton = styled(Button).attrs({ width: '100%', variant: 'text', py: '16px' })`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

interface MoreWalletCardProps extends ButtonProps {
  t: (key: string) => string
}

export const MoreWalletCard: React.FC<MoreWalletCardProps> = ({ t, ...props }) => {
  return (
    <WalletButton variant="tertiary" {...props}>
      <MoreHorizontal width="40px" mb="8px" color="textSubtle" />
      <Text fontSize="14px">{t('More')}</Text>
    </WalletButton>
  )
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss,onHaventWallet }) => {
  const { title, icon: Icon } = walletConfig

  return (
    <WalletButton
      variant="tertiary"
      onClick={() => {
          if(walletConfig.connectorId == 'bitkeep'){
              let provider = window.bitkeep && window.bitkeep.ethereum;
              if (!provider) {
                  onHaventWallet('bitkeep')
                  return;
              }
          }
          if(walletConfig.connectorId == 'injected'){
              let provider = window.ethereum;
              if (!provider) {
                  onHaventWallet('injected')
                  return;
              }
          }
        login(walletConfig.connectorId)
        localStorage.setItem(walletLocalStorageKey, walletConfig.title)
        localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
        onDismiss()
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      {walletConfig.image ? (
        <img src={'../../../../images/icon/' + walletConfig.image} style={{ width: '40px', marginBottom: '8px' }}></img>
      ) : (
        <Icon width="40px" mb="8px" />
      )}

      <Text fontSize="14px">{title}</Text>
    </WalletButton>
  )
}

export default WalletCard
